.bg-gradient {
  // background: linear-gradient(90deg, #f9c7ff, #9e63ff, rgba(0,35,255,1));
  // background: linear-gradient(90deg, #cac7ff, #93cfff, rgba(95,255,228,1));
  // background: linear-gradient(90deg, #ffd98c, #ff9f93, rgba(255,153,197,1));
  // background: linear-gradient(90deg, #00f3ff, #127aff, #3524ff);
  background: linear-gradient(90deg, #00f3ff, #4898ff, #9a91ff);
}

.text-gradient {
  @apply bg-gradient;

  color: transparent;
  background-clip: text;
}
