@tailwind base;
@tailwind components;
@tailwind utilities;

@import "montserrat";
@import "ibm-plex-sans";
@import "softeng";
@import "gradient";
@import "typed";

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;

  @apply bg-bg-dark;

  font-family: IBM Plex Sans;
}

.montserrat {
  font-family: Montserrat;
}

.stripe {
  margin-left: -100vw;
  margin-right: -100vw;
  /* add back negative margin value */
  padding-left: 100vw;
  padding-right: 100vw;
}

.polka {
  opacity: 1;
  background-image: radial-gradient(#4898ff 1px, transparent 1px),
    radial-gradient(#9a91ff 1px, #000000 1px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.isometric {
  background-color: #000057;
  opacity: 0.8;
  background-image: linear-gradient(
      30deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      150deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      30deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      150deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      60deg,
      #ffffff77 25%,
      transparent 25.5%,
      transparent 75%,
      #ffffff77 75%,
      #ffffff77
    ),
    linear-gradient(
      60deg,
      #ffffff77 25%,
      transparent 25.5%,
      transparent 75%,
      #ffffff77 75%,
      #ffffff77
    );
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}

.title-typed {
  @include typed("Made Simple.", "Tailored.", "Custom Built.", "Efficient.", (type: .05, pause-deleted: .25));
}
