@import "gradient";

.softeng-svg {
  pointer-events: none;

  * {
    fill: white;
  }

  line {
    fill: white;
    stroke: white;
  }

  .cls-1 {
    stroke: white;
  }

  .hex-outer {
    stroke: white;
  }

  .hex-inner {
    animation-name: breathe;
    animation-duration: 2s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}

.package-svg {
  .circle {
    animation-duration: 2s;
    animation-direction: alternate;
    animation-name: breathe;
    animation-iteration-count: infinite;
  }

  .cube {
    stroke: white;
    fill: none;
    stroke-width: 0.25px;
  }

  .queue {
    stroke: white;
    stroke-width: 0.75px;
  }

  animation: shoot 6s;
  animation-iteration-count: infinite;
}

@keyframes shoot {
  0% {
    transform: rotate(0deg) translate(0px) scale(0);
  }

  16% {
    transform: rotate(0deg) translateY(-40%) scale(1.5);
  }

  16.9% {
    transform: rotate(0deg) translateY(-40%) scale(0.3);
  }

  17% {
    transform: scale(0);
  }
  
  18% {
    transform: rotate(60deg) translateY(0) scale(0);
  }

  //

  32% {
    transform: rotate(60deg) translateY(-40%) scale(1.5);
  }

  32.9% {
    transform: rotate(60deg) translateY(-40%) scale(0.3);
  }

  33% {
    transform: scale(0);
  }

  34% {
    transform: rotate(120deg) translateY(0) scale(0);
  }

  //

  48% {
    transform: rotate(120deg) translateY(-40%) scale(1.5);
  }

  48.9% {
    transform: rotate(120deg) translateY(-40%) scale(0.3);
  }

  49% {
    transform: scale(0);
  }

  50% {
    transform: rotate(180deg) translateY(0) scale(0);
  }

  //

  64% {
    transform: rotate(180deg) translateY(-40%) scale(1.5);
  }

  64.9% {
    transform: rotate(180deg) translateY(-40%) scale(0.3);
  }

  65% {
    transform: scale(0);
  }

  66% {
    transform: rotate(240deg) translateY(0) scale(0);
  }

  //
  
  80% {
    transform: rotate(240deg) translateY(-40%) scale(1.5);
  }

  80.9% {
    transform: rotate(240deg) translateY(-40%) scale(0.3);
  }

  81% {
    transform: scale(0);
  }

  82% {
    transform: rotate(300deg) translateY(0) scale(0);
  }

  //

  96% {
    transform: rotate(300deg) translateY(-40%) scale(1.5);
  }

  96.9% {
    transform: rotate(300deg) translateY(-40%) scale(0.3);
  }

  97% {
    transform: scale(0);
  }

  98% {
    transform: rotate(0deg) translateY(0px) scale(0);
  }

  //

  100% {
    transform: rotate(0deg) translateY(0px) scale(0);
  }
}

@keyframes breathe {
  0% {
    fill: #00f3ff;
  }

  50% {
    fill: #4898ff;
  }

  100% {
    fill: #9a91ff;
  }
}
