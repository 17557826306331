@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat/static/Montserrat-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}
